import React, { useState } from "react";

import Person from "./Person";

import "../scss/App.scoped.scss";

function App(props) {
  const {
    people = [],
    peopleLoading = true,
    primaryContactId = "",
    verifiedPeople: { highlights: { verified = [], unv = [] } = {} } = {},
  } = props;

  const [showUnverified, setShowUnverified] = useState(false);
  const unverified =
    verified.length < 1 && unv.length < 1 && people.length > 0 ? people : unv;

  const findFullPerson = (id) => {
    return people.find((person) => person.id === id);
  };

  if (peopleLoading) {
    return (
      <div class="people-section">
        <div class="card expanded">
          <div class="card-header">
            <div class="card-title">People</div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-center my-5">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!people.length) {
    return (
      <div class="people-section">
        <div class="card expanded">
          <div class="card-header">
            <div class="card-title">People</div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-center my-5">
              No people data.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div class="people-section">
      <div class="card expanded">
        <div class="card-header">
          <div class="card-title">People</div>
        </div>
        <div class="card-body">
          <div class="section-title main verified">
            Verified ({verified.length})
          </div>
          <div class="people-container">
            {verified
              .filter((person) => findFullPerson(person.id) !== undefined)
              .map((person) => {
                return {
                  highlight: person,
                  details: findFullPerson(person.id),
                };
              })
              .map((person) => {
                return (
                  <Person
                    highlight={person.highlight}
                    details={person.details}
                    primaryContactId={primaryContactId}
                  />
                );
              })}
          </div>
          <div class="section-title main unverified">
            <button
              class="btn btn-link section-title-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowUnverified(!showUnverified);
              }}
            >
              Unverified ({unverified.length})
            </button>
          </div>
          {showUnverified && (
            <div class="people-container">
              {unverified
                .filter((person) => findFullPerson(person.id) !== undefined)
                .map((person) => {
                  return {
                    highlight: person,
                    details: findFullPerson(person.id),
                  };
                })
                .map((person) => {
                  return (
                    <Person
                      highlight={person.highlight}
                      details={person.details}
                      primaryContactId={primaryContactId}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
