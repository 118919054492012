import React from "react";

import App from "./components/App";

export default function Root(props) {
  const { people, peopleLoading, primaryContactId, verifiedPeople } = props;

  return (
    <App
      people={people}
      peopleLoading={peopleLoading}
      primaryContactId={primaryContactId}
      verifiedPeople={verifiedPeople}
    />
  );
}
