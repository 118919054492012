import clsx from "clsx";
import dayjs from "dayjs";
import { truncateWords } from "humanize-plus";
import { isEqual, orderBy, unionWith } from "lodash";
import React, { useState } from "react";

import SocialLink from "../../../../components/js/ui/organization/SocialLink";

import "../scss/Person.scoped.scss";

const orderExperience = (value) => {
  let startDate = dayjs().startOf("year");
  if (value.start) {
    startDate =
      value.start.split(" ").length === 1
        ? dayjs(value.start, "YYYY").startOf("year")
        : dayjs(value.start, "MMMM YYYY");
  }
  if (!value.end) value.end = "present";
  let endDate =
    value.end.toLowerCase() === "present" ? dayjs().endOf("day") : value.end;
  if (typeof endDate === "string") {
    endDate =
      value.end.split(" ").length === 1
        ? dayjs(value.end, "YYYY").startOf("year")
        : dayjs(value.end, "MMMM YYYY");
  }
  return [endDate.valueOf(), startDate.valueOf(), value.company];
};

const orderEducation = (value) => {
  let startDate = dayjs().startOf("year");
  if (value.from) {
    startDate =
      value.from.split(" ").length === 1
        ? dayjs(value.from, "YYYY").startOf("year")
        : dayjs(value.from, "MMMM YYYY");
  }
  if (!value.to) value.to = "present";
  let endDate =
    value.to.toLowerCase() === "present" ? dayjs().endOf("day") : value.to;
  if (typeof endDate === "string") {
    endDate =
      value.to.split(" ").length === 1
        ? dayjs(value.to, "YYYY").startOf("year")
        : dayjs(value.to, "MMMM YYYY");
  }
  return [endDate.valueOf(), startDate.valueOf(), value.title];
};

const renderExperience = (experience, i) => {
  return (
    <div
      key={i}
      className={clsx(
        "relevant-experience",
        experience.end.toLowerCase() === "present" &&
          "relevant-experience-present",
        experience?.type && "relevant-experience-bg"
      )}
    >
      <div className="relevant-experience-title">
        <span>{experience.title}</span>
        {experience.type === "top_tech" && (
          <i className="fas fa-user-check"></i>
        )}
        {experience.type === "exit" && <i className="fas fa-sign-out-alt"></i>}
      </div>
      <div className="relevant-experience-info">
        <span className="relevant-experience-company">
          {experience.company}
          {experience.type === "top_tech" && (
            <i class="relevant-experience-company-reason">
              {topTechReasons[experience.reason]}
            </i>
          )}
        </span>
        <br />
        <span className="relevant-experience-dates">
          {experience.start} -{" "}
          {!experience.end || experience?.end.toLowerCase() === "present"
            ? "Present"
            : experience.end}
        </span>
      </div>
    </div>
  );
};

const renderEducation = (education, i) => {
  return (
    <div
      key={i}
      className={clsx(
        "relevant-experience",
        !education.to && "relevant-experience-present",
        education.type && "relevant-experience-bg"
      )}
    >
      <div className="relevant-experience-title">
        <span>{education.title}</span>
        {education.type === "good" && <i className="fas fa-user-graduate"></i>}
      </div>
      <div className="relevant-experience-info">
        <span className="relevant-experience-company">
          {education.subtitle}
        </span>
        <span className="relevant-experience-dates">
          {education.from} -{" "}
          {!education.to || education.to.toLowerCase() === "present"
            ? "Present"
            : education.to}
        </span>
      </div>
    </div>
  );
};

const findRelativeExperience = (companyId, experiences) => {
  return experiences.find(
    (experience) => experience.organization_id === companyId
  );
};

const topTechReasons = {
  crunchbase_rank: "High crunchbase rank",
  toptier_investors: ">$10M by a TopTier VC",
  whitelisted_name: "Whitelisted",
  large_funding_amount: ">$20M funding",
};

function Person(props) {
  const { highlight, details, primaryContactId } = props;

  const [allExperiences, setAllExperiences] = useState(false);
  const [allEducation, setAllEducation] = useState(false);

  const relevantExitExperiences =
    details?.ml_features?.has_previous_exit?.highlights?.exits
      .filter((exit) => !Object.keys(exit).includes("bio_match"))
      .map((exit) =>
        findRelativeExperience(exit.previous_exit_org_id, details.experience)
      )
      .map((exit) => {
        if (!exit) {
          exit = {};
        }
        exit.type = "exit";
        return exit;
      });

  const relevantTopTechExperiences =
    details?.ml_features?.days_at_top_tech?.highlights?.experiences
      .filter((experience) => experience !== undefined)
      .map((experience) => {
        experience.experience.reason = experience.reason.reason;
        experience.experience.type = "top_tech";
        return experience.experience;
      });

  let relevantExperiences = [
    highlight?.experience,
    relevantExitExperiences,
    relevantTopTechExperiences,
  ]
    .flat()
    .filter((experience) => experience !== undefined)
    .map((experience) => {
      const end = experience.end ? experience.end : "present";
      experience.end = end;
      return experience;
    });

  relevantExperiences = orderBy(
    relevantExperiences,
    orderExperience[("desc", "desc", "asc")]
  );

  let relevantEducation =
    details?.ml_features?.good_education?.highlights?.good_educations.map(
      (education) => {
        education.type = "good";
        return education;
      }
    );

  relevantEducation = orderBy(relevantEducation, orderEducation, [
    "desc",
    "desc",
    "asc",
  ]);

  let notRelevantExperiences = unionWith(
    relevantExperiences,
    details?.experience,
    (a, b) => {
      const tempA = {
        to: a.to,
        from: a.from,
        company: a.company,
        title: a.title,
      };
      const tempB = {
        to: b.to,
        from: b.from,
        company: b.company,
        title: b.title,
      };
      return isEqual(tempA, tempB);
    }
  );

  notRelevantExperiences = notRelevantExperiences
    .filter((experience) => experience !== undefined)
    .map((experience) => {
      const end = experience.end ? experience.end : "present";
      experience.end = end;
      return experience;
    });

  notRelevantExperiences = orderBy(notRelevantExperiences, orderExperience, [
    "desc",
    "desc",
    "asc",
  ]);

  let notRelevantEducation = unionWith(
    relevantEducation,
    details?.education,
    (a, b) => {
      const tempA = {
        from: a.from,
        to: a.to,
        subtitle: a.subtitle,
        title: a.title,
      };
      const tempB = {
        from: b.from,
        to: b.to,
        subtitle: b.subtitle,
        title: b.title,
      };
      return isEqual(tempA, tempB);
    }
  );

  notRelevantEducation = notRelevantEducation
    .filter((education) => education !== undefined)
    .map((education) => {
      const to = education.to ? education.to : "present";
      education.to = to;
      return education;
    });

  notRelevantEducation = orderBy(notRelevantEducation, orderEducation, [
    "desc",
    "desc",
    "asc",
  ]);

  return (
    <div className="profile-person">
      <div className="basic-info">
        <div className="basic-info-image-container">
          <img
            className="basic-info-image"
            src={details?.image_url}
            alt={details?.name}
          />
        </div>
        <div className="basic-info-name-title-container">
          <h3 className="basic-info-name" data-testid="name">
            <a href={`/people/${details.id}`}>{details.name}</a>
          </h3>
          <p className="basic-info-title" data-testid="title">
            {highlight?.experience ? highlight.experience.title : details.title}
          </p>
        </div>
      </div>
      <p
        className={clsx(
          "verified-role",
          highlight.role === "founder" && "verified-role-founder",
          highlight.role === "team" && "verified-role-team"
        )}
        data-testid="role"
      >
        {highlight.role}
      </p>
      <div className="links-container">
        {details.id === primaryContactId && (
          <a className="primary-contact" href={`mailto:${details.email}`}>
            <i className="fas fa-envelope"></i> {details.email}
          </a>
        )}
        <SocialLink source="linkedin" link={details.linkedin_url} />
        <SocialLink source="website" link={details.website_url} />
        <SocialLink source="twitter" link={details.twitter_url} />
        <SocialLink source="angellist" link={details.angellist_url} />
        <SocialLink source="facebook" link={details.facebook_url} />
        <SocialLink source="github" link={details.github_url} />
      </div>
      <div className="verfied-highlights" data-testid="highlights">
        {details.ml_features?.is_technological_person?.score === 1 && (
          <span
            className="verified-label verified-tech-person"
            data-testid="techie"
          >
            <i className="fas fa-chalkboard-teacher"></i>Techie
          </span>
        )}
        {details.ml_features?.days_at_top_tech?.score > 0 && (
          <span
            className="verified-label verified-worked-at-top-tech"
            data-testid="top-tech"
          >
            <i className="fas fa-user-check"></i>Worked at Top Tech Company
          </span>
        )}
        {details.ml_features?.has_previous_exit?.score === 1 && (
          <span
            className="verified-label verified-has-previous-exit"
            data-testid="previous-exit"
          >
            <i className="fas fa-sign-out-alt"></i>Previous Exit
          </span>
        )}
        {details.ml_features?.good_education?.score >= 1 && (
          <span
            className="verified-label verified-good-education"
            data-testid="good-education"
          >
            <i className="fas fa-user-graduate"></i>Good Education
          </span>
        )}
      </div>
      {details.bio && (
        <div className="bio">
          <p>{truncateWords(details.bio, 30)}</p>
        </div>
      )}
      <div className="experiences-education">
        {(relevantExperiences?.length > 0 ||
          details?.experience?.length > 0) && (
          <div className="experiences-education-bar">
            <h4 className="experiences-education-ex">Experiences</h4>
            <button
              className="btn"
              onClick={(e) => {
                setAllExperiences(!allExperiences);
              }}
            >
              <i className="fas fa-arrows-alt-v"></i>
            </button>
          </div>
        )}
        {relevantExperiences &&
          !allExperiences &&
          relevantExperiences.map(renderExperience)}
        {notRelevantExperiences &&
          allExperiences &&
          notRelevantExperiences
            .map((experience) => {
              const end = experience.end ? experience.end : " present";
              experience.end = end;
              return experience;
            })
            .map(renderExperience)}
        {(relevantEducation?.length > 0 || details?.education?.length > 0) && (
          <div className="experiences-education-bar">
            <h4 className="experiences-education-ed">Education</h4>
            <button
              className="btn"
              onClick={(e) => {
                setAllEducation(!allEducation);
              }}
            >
              <i className="fas fa-arrows-alt-v"></i>
            </button>
          </div>
        )}
        {relevantEducation &&
          !allEducation &&
          relevantEducation.map(renderEducation)}
        {notRelevantEducation &&
          allEducation &&
          notRelevantEducation.map(renderEducation)}
      </div>
    </div>
  );
}

export default Person;
